<template>
  <v-main>
    <v-card class="mx-auto my-14" max-width="374" color="#fdf9f9">
      <v-row>
        <v-col class="text-center">
          <v-img class="ma-auto" src="../assets/logo.png" width="150"></v-img>
        </v-col>
      </v-row>
      <v-form v-on:submit.prevent="onSubmit" class="text-center" ref="formRef">
        <v-card-text>
          <h2 class="primary--text">Crie uma nova senha</h2>
          <v-row dense class="mt-2">
            <v-col>
              <p class="primary--text">Defina uma nova senha para acessar o sistema</p>
              <v-text-field :append-icon="showPassword.password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword.password ? 'text' : 'password'"
                @click:append="showPassword.password = !showPassword.password" outlined v-model="password"
                placeholder="Senha" dense :rules="rules.password" />
              <v-text-field :append-icon="showPassword.confirm ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword.confirm ? 'text' : 'password'"
                @click:append="showPassword.confirm = !showPassword.confirm" class="mt-2" outlined
                v-model="passwordConfirm" placeholder="Repetir senha" dense
                :rules="[...rules.passwordConfirm, isPasswordMatching]" />

            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-0">
          <v-container>
            <v-btn :loading="isLoading('patch')" type="submit" color="primary" class="white--text" block elevation="0">
              Redefinir senha
            </v-btn>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-main>
</template>

<script>
import UtilsFunc from '../service/utilsFunc';
import UtilsService from '../service/utilsService';
import axios from 'axios'

function validatePasswordCharacters(text) {
  if (!text.match(/[a-z]/g)) return 'A senha deve conter letras minúsculas'
  if (!text.match(/[A-Z]/g)) return 'A senha deve conter letras maiúsculas'
  if (!text.match(/[0-9]/g)) return 'A senha deve conter letras números'
  if (!text.match(/\W/g)) return 'A senha deve conter letras simbolos'
  if (text.length < 8) return 'A senha deve conter letras no mínimo 8 caracteres'
  return true
}

export default {
  name: 'ResetPassword',
  data: () => UtilsFunc.withCRUDUtils({
    password: '',
    passwordConfirm: '',
    token: '',
    uid: '',
    showPassword: {
      confirm: false,
      password: false
    },
    rules: {
      password: [UtilsFunc.isEmptyField('Campo obrigatório'), validatePasswordCharacters],
      passwordConfirm: [UtilsFunc.isEmptyField('Campo obrigatório')]
    }
  }),
  methods: {
    async onSubmit() {
      const isValid = this.$refs.formRef.validate()
      if (!isValid) return
      const LOADING_NAME = 'patch'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await axios.get(`${UtilsService.urlApi()}/contas/password-reset/${this.uid}/${this.token}/`)
        if (!data.success) return this.$toast.error('Token inválido')
        await axios.patch(`${UtilsService.urlApi()}/contas/password-reset-complete/`, {
          password: this.password,
          uidb64: this.uid,
          token: this.token
        })
        await this.$toast.success("Senha redefinida com sucesso!")
        this.$router.push('/')
      } catch (error) {
        if (error.response.data.detail) return this.$toast.error(error.response.data.detail)
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    isPasswordMatching(passwordConfirm) {
      if (passwordConfirm !== this.password) return 'Senhas não estão iguais'
      return true
    }
  },
  mounted() {
    const { uid, token } = this.$route.query
    if (!uid || !token) return
    this.uid = uid
    this.token = token
  }
}
</script>
